@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OpenSans";
  src: url(../assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "WorkSans";
  src: url(../assets/fonts/WorkSans-VariableFont_wght.ttf);
}

:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  --accent-color: #635bff;
  --radius: 3px;
  width:100%;
  height:100%;
}

html {
  font-family: "WorkSans", sans-serif;
  width:100%;
  height:100%;
}

body {
  @apply bg-white-500;
  width:100%;
  height:100%;
  margin:0;
  padding:0;
}
p {
  @apply text-primary-200;
}
.animation-hover:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  height: 2px;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  @apply w-0 bg-primary-300 left-1/2 block bottom-0 absolute;
}
.animation-active:after {
  @apply left-0 w-full;
}
.animation-hover:hover:after {
  @apply left-0 w-full;
}

*, ::before, ::after {
  border-width: 0;
  border-style: solid;
  border-color: currentColor /* or gray-DEFAULT / gray-400 */;
}

li.custom-list:before {
  content: "\2022"; /* bullet point, for screen readers */
  text-indent: -9999999px; /* move the bullet point out of sight */

  width: 0.4em;
  height: 1em;
  background-repeat: no-repeat;

  background-size: 0.4em 0.7em;
  background-position: 0 0.3em;
  font-size: 300%;
  top: -0.35em;
  @apply absolute block;
}
li.circle-check:before {
  background-image: url("../assets/Icon/checklist.svg");
  left: -0.7em;
  top: -0.4em;
}
li.check:before {
  left: -0.5em;
  background-image: url("../assets/Icon/jam_check.svg");
  top: -0.5em;
  font-size: 400%;
}
/* Slideshow */
.slick-dots li {
  @apply inline-block;
}
.slick-dots span {
  @apply bg-gray-1000;
}
.slick-dots .slick-active span {
  @apply bg-primary-800 rounded-l-full rounded-r-full w-12;
}

main {
  width: 480px;
}

form > * {
  margin: 10px 0;
}

button.stripe {
  background-color: var(--accent-color);
}

button.stripe {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button.stripe:hover {
  filter: contrast(115%);
}
button.stripe:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button.stripe:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

label {
  display: block;
}

a.stripe {
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: 0.6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}

#payment-form {
  min-width: 600px;
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

.form-container {
  padding: 20px;
  display: flex;
  font-family: "Rubik", sans-serif;
  justify-content: center;
  font-size: 1.2em;
  color: var(--dark-terminal-color);
}

/* purgecss end ignore */
/* @tailwind utilities; */
/* purgecss start ignore */
/* purgecss end ignore */
